const colors = {
  transparent: 'transparent',
  white: 'white',
  offWhite: '#f7f7fa',
  offWhiteAlt: '#f7f7faea',
  whitish: '#F3F4F5',
  homeIconGrey: '#E7E7E7',
  blueWhite: '#EEEFF3',
  lightestGrey: '#e5e6ec',
  borderModal: '#EEE',
  lightBlueGrey: '#DEDEE5',
  lightGrey: '#D6D6D6',
  blueGrey: '#C6C7D2',
  friendCardTitle: '#B6B7BF',
  skeletonGrey: '#b0b0b0',
  grey: '#AEAEAE',
  sparcsGrey: '#A3A3A3',
  darkSonicSilver: '#9497A9',
  skipGrey: '#919191',
  darkGrey: '#707070',
  sonicSilverShade3: '#686B8252',
  sonicSilverShade2: '#686B8233',
  sonicSilverShade: '#686B8229',
  sonicSilverLightShade: '#686B821F',
  sonicSilverLightestShade: '#686B8214',
  sonicSilver: '#686b82',
  onix: '#484b5e',
  onixShade: '#484B5E50',
  ratingChipFilled: '#404040',
  modalMessage: 'rgba(100,100,100,0.3)',
  darkerGrey: '#292B38',
  darkestGrey: '#22232D',
  softBlack: '#101114',
  black: 'black',
  red: 'red',
  authSuccess: '#23BA75',
  authGuideSuccess: '#149E61',
  cheerinGreen: '#08844F',
  pushColor: '#3E8894',
  sparcs: 'rgb(85, 220, 218)',
  CheckBoxOnTintColor: '#A991F7',
  CheckBoxOnFillColor: '#9085FD',
  addButtonColor: '#4F4FF8',
  checkWrapShadow: '#1018280A',
  transparentWhite: 'rgba(255, 255, 255, 0.0)',
  cherryLight: '#FE696C29',
  inputRed: '#F5395E',
  badgeColor: '#FFB5B5',
  cherry: {
    c50: '#FFECED',
    c100: '#FFC5C6',
    c200: '#FFA9AA',
    c300: '#FE8284',
    c400: '#FE696C',
    c500: '#FE4447',
    c600: '#E73E41',
    c700: '#B43032',
    c800: '#8C2527',
    c900: '#6B1D1E',
  },
  sunRay: {
    c50: '#FFF7E7',
    c100: '#FFE7B5', // only shade in use
    c200: '#FFDC91',
    c300: '#FFCC5E',
    c400: '#FFC23F',
    c500: '#FFB30F',
    c600: '#E8A30E',
    c700: '#B57F0B',
    c800: '#8C6208',
    c900: '#6B4B06',
  },
  sea: {
    c50: '#E6F5F6',
    c100: '#B0E0E4',
    c200: '#8AD1D7',
    c300: '#54BCC4',
    c400: '#33AFB9',
    c500: '#009BA7',
    c600: '#008D98',
    c700: '#006E77',
    c800: '#00555C',
    c900: '#004146',
  },
  brightening: {
    t08: 'rgba(255, 255, 255, 0.08)',
    t32: 'rgba(255, 255, 255, 0.32)',
    t53: 'rgba(255, 255, 255, 0.53)',
    t56: 'rgba(255, 255, 255, 0.56)',
    t63: 'rgba(255, 255, 255, 0.63)',
    t73: 'rgba(255, 255, 255, 0.73)',
    t88: 'rgba(255, 255, 255, 0.88)',
    t94: 'rgba(255, 255, 255, 0.94)',
  },
  darkening: {
    t10: 'rgba(0, 0, 0, 0.1)',
    t20: 'rgba(0, 0, 0, 0.2)',
    t24: 'rgba(0, 0, 0, 0.24)',
    t30: 'rgba(0, 0, 0, 0.3)',
    t40: 'rgba(0, 0, 0, 0.4)',
    t50: 'rgba(0, 0, 0, 0.5)',
    t60: 'rgba(0, 0, 0, 0.6)',
    t67: 'rgba(0, 0, 0, 0.67)',
    t70: 'rgba(0, 0, 0, 0.7)',
    t80: 'rgba(0, 0, 0, 0.8)',
    t90: 'rgba(0, 0, 0, 0.9)',
    t93: 'rgba(0, 0, 0, 0.93)',
  },
};

export default colors;
