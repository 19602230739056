import {
  Grid, Container, CircularProgress, Paper, Box, Typography, Avatar, TextField, Button, Snackbar, Alert, Link,
} from '@mui/material';
import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { BsPersonPlus } from 'react-icons/bs';
import { CiCalendar, CiLocationOn } from 'react-icons/ci';
import { IoPeopleOutline } from 'react-icons/io5';
import Lottie from 'react-lottie';
import colors from './assets/colors';
import * as animationData from './assets/lottie/checkmark.json';
import qr_code from './assets/img/qr_code.png';
import logo_cheerin from './assets/img/logo.svg';
import calendar_outlook from './assets/img/calendar_outlook.png';
import calendar_google from './assets/img/calendar_google.png';
import badge_google_play from './assets/img/badge_google_play.png';
import badge_app_store from './assets/img/badge_app_store.png';

const App = () => {
  const [event, setEvent] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState(false);
  const [firstNameErrorText, setFirstNameErrorText] = useState(' ');
  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState(false);
  const [lastNameErrorText, setLastNameErrorText] = useState(' ');
  const [unknownEventError, setUnknownEventError] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [joined, setJoined] = useState(false);
  const bottomRef = useRef();
  const [bottomAnimation, setBottomAnimation] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const handleWindowSizeChange = () => {
    setIsMobile(window.innerWidth < 768);
  };
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const eventId = window.location.pathname.slice(1);

  const host_dev = 'https://operations.dev.cheerin.app';
  const host_prod = 'https://operations.prod.cheerin.app';

  const host = window.location.search.includes('dev=true') ? host_dev : host_prod;

  const postJoin = async () => {
    const data = {
      sharedEventId: eventId,
      firstName,
      lastName,
    };

    try {
      const res = await axios.post(`${host}/schedule/joinEvent`, data);
      if (res?.data.errorResponse === null) {
        setJoined(true);
        /* setTimeout(() => {
          bottomRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 300);
         */
        setTimeout(() => {
          setBottomAnimation(true);
        }, 600);
      } else {
        setSnackMessage('Error: Cannot join event');
      }
    } catch (error) {
      setSnackMessage('Error: Cannot join event');
    }
  };

  const handleSnackClose = () => {
    setSnackMessage('');
  };

  const createOffset = (date) => {
    const sign = date.getTimezoneOffset() > 0 ? '%2D' : '%2B';
    const offset = Math.abs(date.getTimezoneOffset());
    const hours = Math.floor(offset / 60)
      .toString()
      .padStart(2, '0');
    const minutes = (offset % 60).toString().padStart(2, '0');
    return `${sign + hours}%3A${minutes}`;
  };

  useEffect(() => {
    const getEvent = async () => {
      try {
        let res = '';

        try {
          res = JSON.parse(document.getElementById('root').getAttribute('data-raw').replaceAll("'", '"'));
        } catch (e) {
          res = await axios.get(`${host}/schedule/event?sharedEventId=${eventId}`, { timeout: 1500 });
        }

        if (res?.data.errorResponse === null) {
          const resp = res?.data.payload;
          resp.activityTitle = resp.name || resp.activity.activityName;

          const hostGoogleCal = 'https://calendar.google.com/calendar/render?action=TEMPLATE';

          const hostOutlookCal = 'https://outlook.live.com/calendar/0/deeplink/compose?path=%2Fcalendar%2Faction%2Fcompose&rru=addevent';
          const mStart = new Date(`${resp.ownerDate.replaceAll('-', '/')}${resp.time ? ` ${resp.time}:00` : ' 12:00:00'}`);
          const mEnd = new Date(mStart.getTime());
          mEnd.setHours(mStart.getHours() + 1);
          mStart.setMinutes(mStart.getMinutes() - new Date().getTimezoneOffset());
          mEnd.setMinutes(mEnd.getMinutes() - new Date().getTimezoneOffset());
          resp.date = `${mStart.getDate().toString().padStart(2, '0')}. ${mStart.toLocaleString('default', { month: 'long' })} ${mStart.getFullYear()}`;
          resp.ownerTime = `${mStart.getHours().toString().padStart(2, '0')}:${mStart.getMinutes().toString().padStart(2, '0')}`;

          document.title = `${resp.owner.firstName} invited you to ${resp.activityTitle} on ${mStart.getDate().toString().padStart(2, '0')}. ${mStart.toLocaleString('default', { month: 'short' })}. Join now!`;

          const diff = new Date(mStart.getTime()).setHours(0, 0, 0, 0);
          const diffTime = diff - new Date().setHours(0, 0, 0, 0);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

          switch (diffDays) {
            case 0: {
              resp.date = `Today, ${resp.date}`;
              break;
            }
            case -1: {
              resp.date = `Yesterday, ${resp.date}`;
              break;
            }
            case 1: {
              resp.date = `Tomorrow, ${resp.date}`;
              break;
            }
            default:
              break;
          }

          const googleDateStr = `&dates=${resp.time ? `${mStart.toISOString()}%2F${mEnd.toISOString()}`.replaceAll('-', '').replaceAll(':', '').replaceAll('.', '') : `${resp.ownerDate.replaceAll('-', '')}/${resp.ownerDate.replaceAll('-', '')}`}`;
          resp.linkGoogleCal = `${hostGoogleCal + googleDateStr}&details=Download now: https://download.cheerin.app<br/>${resp.description ?? ''}&location=${resp.location ?? ''}&text=${resp.activityTitle} with ${resp.owner.firstName} on cheerin'`;

          mStart.setMinutes(mStart.getMinutes() - new Date().getTimezoneOffset());
          mEnd.setMinutes(mEnd.getMinutes() - new Date().getTimezoneOffset());

          const outlookDateStr = `&startdt=${resp.time ? mStart.toISOString().slice(0, -5).replaceAll(':', '%3A') + createOffset(mStart) : resp.ownderDate}&enddt=${resp.time ? mEnd.toISOString().slice(0, -5).replaceAll(':', '%3A') + createOffset(mEnd) : ''}&allday=${resp.time ? 'false' : 'true'}`;
          resp.linkOutlookCal = `${hostOutlookCal + outlookDateStr}&body=${encodeURI(`Download now: https://download.cheerin.app<br/>${resp.description ?? ''}`)}&location=${encodeURI(resp.location ?? '')}&subject=${encodeURI(`${resp.activityTitle} with ${resp.owner.firstName} on cheerin'`)}`;

          setEvent(resp);
          setUnknownEventError(false);
        } else {
          document.title = "cheerin' - Event not found";
          setUnknownEventError(true);
        }
      } catch (error) {
        setUnknownEventError(true);
      }
    };

    getEvent();
  }, [eventId]);

  const handleChangeFirstName = (e) => {
    const name = e.target.value;
    setFirstNameError(name.length === 0);
    setFirstNameErrorText(name.length === 0 ? 'First Name missing' : ' ');
    setFirstName(name);
  };

  const handleChangeLastName = (e) => {
    const name = e.target.value;
    setLastNameError(name.length === 0);
    setLastNameErrorText(name.length === 0 ? 'Last Name missing' : ' ');
    setLastName(name);
  };

  const handleJoin = () => {
    let error = false;
    if (firstName.length === 0) {
      error = true;
      setFirstNameError(true);
      setFirstNameErrorText('First Name missing');
    }
    if (lastName.length === 0) {
      error = true;
      setLastNameError(true);
      setLastNameErrorText('Last Name missing');
    }
    if (!error) {
      postJoin();
    }
  };

  const checkmark = {
    loop: false,
    autoplay: bottomAnimation,
    animationData,
  };

  const download_now = (
    <Grid container direction="row" justifyContent="space-between" gap={3}>
      <Grid item>
        <Typography variant="h5">DOWNLOAD NOW</Typography>
        <Typography variant="h6">Don&apos;t miss out & join your friends on cheerin&apos;!</Typography>
        <Box sx={{ mb: 2, flex: 1 }} />
        {isMobile ? (
          <Button color="cheerin" variant="contained" size="large" href="https://download.cheerin.app">
            Download the app & join the event
          </Button>
        ) : (
          <Grid container direction="row" justifyContent="flex-start" gap={3}>
            <a href="https://apps.apple.com/app/1538926865" target="_blank" rel="noreferrer">
              <img src={`${badge_app_store}`} width="auto" height={70} alt="Download cheerin' from iOS App Store" loading="lazy" />
            </a>

            <a href="https://play.google.com/store/apps/details?id=app.sparcs" target="_blank" rel="noreferrer">
              <img src={`${badge_google_play}`} width="auto" height={70} alt="Download cheerin' from Google Play" loading="lazy" />
            </a>
          </Grid>
        )}
      </Grid>
      {!isMobile && (
        <img
          src={`${qr_code}`}
          width="auto"
          alt="cheerin' download qr code"
          loading="lazy"
          style={{
            backgroundColor: colors.white,
            alignSelf: 'center',
            height: '20vw',
            maxHeight: '200px',
          }}
        />
      )}
    </Grid>
  );

  return (
    <Container maxWidth="md">
      <Box sx={{ mb: 12 }} />
      <a href="https://cheerin.app" target="_blank" rel="noreferrer">
        <img src={`${logo_cheerin}`} width="auto" height={50} alt="cheerin' logo" loading="lazy" style={{ position: 'absolute', top: 20, left: 20 }} />
      </a>
      {unknownEventError ? (
        <>
          <Box sx={{ mb: 32 }} />
          <Paper
            elevation={8}
            sx={{
              backgroundColor: colors.darkening.t40,
              backdropFilter: 'blur(5px)',
              padding: 2,
              display: 'flex',
              flexDirection: 'column',
              marginBottom: 50,
            }}
          >
            <Typography variant="h3" sx={{ m: 4 }} textAlign="center">
              Sorry, we could not find this event
            </Typography>
          </Paper>
        </>
      ) : (
        !event && <CircularProgress sx={{ alignSelf: 'center' }} />
      )}

      {event && (
        <>
          {joined ? (
            <Grid container sx={{ m: 1 }} direction="column" gap={2}>
              <Lottie options={checkmark} height={130} width={130} />
              <Typography variant="h4">Congrats, {firstName}!</Typography>
              <Typography variant="h6">
                You are participating in {event.owner.firstName}&apos;s {event.activity.activityName.toLowerCase()} event{event.activity.activityName !== event.activityTitle && `: ${event.activityTitle}`}.
              </Typography>
            </Grid>
          ) : (
            <Grid container sx={{ m: 1 }} justify="center" direction="column" gap={2}>
              <Avatar
                src={event.owner.profilePictureUrl}
                sx={{
                  width: '28vw',
                  height: '28vw',
                  maxWidth: 130,
                  maxHeight: 130,
                  alignSelf: 'center',
                }}
              />
              <Box>
                <Typography variant="h3" display="inline">
                  {event.owner.firstName} invited you to:
                </Typography>
                <Typography variant="h4" display="inline">
                  {` ${event.activityTitle}`}
                </Typography>
                {event.joinable && (
                  <>
                    <Typography variant="h3">Do you want to join?</Typography>
                    <Box sx={{ mt: 2 }} />
                    <Grid container rowSpacing={0} columnSpacing={3} flexGrow={1}>
                      <Grid item xs={12} sm={6}>
                        <TextField fullWidth InputProps={{ disableUnderline: true }} id="firstName" label="First Name" variant="filled" value={firstName} error={firstNameError} onChange={handleChangeFirstName} helperText={firstNameErrorText} />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField fullWidth InputProps={{ disableUnderline: true }} id="lastName" label="Last Name" variant="filled" value={lastName} error={lastNameError} onChange={handleChangeLastName} helperText={lastNameErrorText} />
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Button color="cheerin" sx={{ mb: 1 }} variant="contained" size="large" onClick={handleJoin} startIcon={<BsPersonPlus size={20} color="white" />} fullWidth>
                        Join
                      </Button>
                    </Grid>
                  </>
                )}
              </Box>
            </Grid>
          )}
          {!event.joinable && (
            <Paper
              elevation={8}
              sx={{
                backgroundColor: colors.darkening.t40,
                backdropFilter: 'blur(5px)',
                padding: 2,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography variant="h3" sx={{ m: 4 }} textAlign="center">
                This event has ended
              </Typography>
            </Paper>
          )}

          {/* FACTS */}
          <Box sx={{ mt: 2.5 }} />
          <Paper
            elevation={8}
            sx={{
              backgroundColor: colors.darkening.t40,
              backdropFilter: 'blur(5px)',
              padding: 3,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <>
              {/* ACTIVITY TYPE */}
              <Grid container direction="row" sx={{ mt: 1, mb: 1 }}>
                <Grid container width={50} alignItems="center" justifyContent="center">
                  <img src={event.activity.activityIconSvg} width="auto" style={{ WebkitFilter: 'invert(1)', filter: 'invert(1)' }} height={36} alt="activity type" loading="lazy" />
                </Grid>
                <Typography sx={{ alignSelf: 'center' }}>{event.activity.activityName}</Typography>
              </Grid>

              {/* PARTICIPANTS */}
              {event.acceptedCount + event.publicCount > 0 && (
                <Grid container direction="row" sx={{ mt: 1, mb: 1 }} wrap="nowrap">
                  <Grid container width={50} justifyContent="center" flexShrink={0}>
                    <IoPeopleOutline size={32} color="white" />
                  </Grid>
                  <Grid item alignItems="center" justifyContent="center" flexGrow={1}>
                    <Typography>
                      {event.acceptedNames.length} Participant{event.acceptedNames.length > 1 && 's'}
                    </Typography>
                    <Typography variant="body2">
                      {event.acceptedNames.join(', ')} {event.openCount > 0 && ` + ${event.openCount} invited`}
                    </Typography>
                  </Grid>
                </Grid>
              )}

              {/* DATE & TIME */}
              <Grid container direction="row" sx={{ mt: 1, mb: 1 }}>
                <Grid container width={50} alignItems="center" justifyContent="center">
                  <CiCalendar size={32} color="white" />
                </Grid>
                <Typography sx={{ alignSelf: 'center' }}>
                  {event.date}
                  {event?.time && ` • ${event.ownerTime}`}
                </Typography>
              </Grid>

              {/* LOCATION */}
              {event?.location && (
                <Grid container direction="row" sx={{ mt: 1, mb: 1 }}>
                  <Grid container width={50} alignItems="center" justifyContent="center">
                    <CiLocationOn size={32} color="white" />
                  </Grid>
                  <a href={`https://maps.google.com/?q=${event.location}`} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                    <Typography sx={{ alignSelf: 'center' }}>{event.location}</Typography>
                  </a>
                </Grid>
              )}
            </>
          </Paper>

          <Box sx={{ mt: 2 }} />

          {/* ADD TO CALENDAR */}
          {joined && (
            <>
              <Paper
                elevation={8}
                sx={{
                  backgroundColor: colors.darkening.t40,
                  backdropFilter: 'blur(5px)',
                  padding: 3,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography>Add this event to you favourite calendar</Typography>
                <Box sx={{ mt: 2 }} />
                <Grid container direction="row" columnSpacing={3} flexGrow={1}>
                  <Grid item xs={12} sm={6}>
                    <Button color="transparent" sx={{ mb: 1 }} variant="contained" size="large" href={event.linkOutlookCal} startIcon={<img src={`${calendar_outlook}`} alt="Outlook Calendar" loading="lazy" />} fullWidth>
                      Add to Outlook Calendar
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button color="transparent" sx={{ mb: 1 }} variant="contained" size="large" href={event.linkGoogleCal} startIcon={<img src={`${calendar_google}`} alt="Google Calendar" loading="lazy" />} fullWidth>
                      Add to Google Calendar
                    </Button>
                  </Grid>
                </Grid>
                <Box ref={bottomRef} />
              </Paper>
              <Box sx={{ mt: 2 }} />
            </>
          )}

          {/* DOWNLOAD NOW */}
          <>
            <Paper
              variant="outlined"
              sx={{
                backgroundColor: colors.darkening.t40,
                backdropFilter: 'blur(5px)',
                padding: 3,
                display: 'flex',
                flexDirection: 'column',
                borderColor: colors.cherry.c600,
                borderWidth: 2,
              }}
            >
              {download_now}
            </Paper>
            <Box sx={{ mt: 3 }} />
            <Typography variant="body2" sx={{ alignSelf: 'center' }}>
              By signing up you are agreeing to our{' '}
              <Link color="inherit" href="https://cheerin.app/terms" target="_blank" rel="noopener">
                Terms or Service
              </Link>
              . Your privacy matters to us, and we handle your data with care in line with our{' '}
              <Link color="inherit" href="https://cheerin.app/privacy" target="_blank" rel="noopener">
                Privacy Policy
              </Link>
              .
            </Typography>
            <Box sx={{ mt: 2.5 }} />
          </>
        </>
      )}
      <Snackbar open={snackMessage !== ''} autoHideDuration={6000} onClose={handleSnackClose}>
        <Alert elevation={6} variant="filled" severity="error">
          {snackMessage}{' '}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default App;
