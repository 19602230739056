import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import App from './App';
import bg from './assets/img/bg.jpeg';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/700.css';
import colors from './assets/colors';

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

const theme = createTheme({
  palette: {
    cheerin: createColor(colors.cherry.c600),
    transparent: createColor('#88888833'),
  },
  typography: {
    fontFamily: ['Inter', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    h3: {
      fontWeight: 700,
      fontSize: '1.4rem',
      '@media (min-width:900px)': {
        fontSize: '2.0rem',
      },
    },
    h4: {
      fontWeight: 500,
      fontSize: '1.4rem',
      '@media (min-width:900px)': {
        fontSize: '2.0rem',
      },
    },
    h5: {
      fontWeight: 700,
      fontSize: '1rem',
      '@media (min-width:900px)': {
        fontSize: '1.5rem',
      },
    },
    h6: {
      fontWeight: 500,
      fontSize: '1rem',
      '@media (min-width:900px)': {
        fontSize: '1.5rem',
      },
    },

    body1: {
      fontWeight: 500,
      fontSize: '1.1rem',
      '@media (min-width:900px)': {
        fontSize: '1.4rem',
      },
    },
    body2: {
      fontWeight: 500,
      fontSize: '0.9rem',
      '@media (min-width:900px)': {
        fontSize: '1.2rem',
      },
    },
    allVariants: {
      color: colors.white,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundImage: `url(${bg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minWidth: '100%',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          // justifyContent: 'center',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 50,
          textTransform: 'none',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: '#484848',
          marginRight: '8px',
          borderRadius: '4px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          borderColor: colors.black,
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: colors.cherry.c600,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        input: {
          background: colors.darkening.t50,
          borderRadius: 16,
        },
        notchedOutline: {
          borderColor: colors.sonicSilver,
          borderWidth: 2,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: colors.white,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            color: '#FF0000',
          },
          '&.Mui-focused': {
            color: colors.white,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            color: '#FF0000',
          },
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <App />
  </ThemeProvider>,
);
